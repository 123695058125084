<template>
    <div class="navbar">

        <drop-down :menu-title="name" dark-mode="auto">
            <section class="option">

                <span class="desc">{{ email }}</span>
            </section>

            <section class="option">
                <button class="button" @click="logout">Logout</button>
            </section>

        </drop-down>
    </div>
</template>

<script>
import DropDown from './DropDown.vue'
export default {
    components: { DropDown },
    data() {
        return {
            email: '',
            name: ''
        }
    },
    mounted() {
        this.email = localStorage.getItem('panelEmail')
        this.name = localStorage.getItem('panelFName')

    },
    methods: {
        logout() {
            localStorage.clear();
            sessionStorage.clear()
            this.$router.push({ name: 'Login' })
        }
    }
}
</script>

<style></style>