import axios from 'axios'
const token = localStorage.getItem('panelToken')
const baseUrl = 'https://trk.everset.dev'

const login = (url, data) => {
    return axios
        .post(
            baseUrl + url, data
        )
        .then((response) => {
            return response
        })
        .catch((error) => {

            console.log(error);
            return error
        });
}
const post = (url, data) => {

    return axios
        .post(
            baseUrl + url, data,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('panelToken')}` } }
        )
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.log(error);
            return error

        });
}

const patch = (url, data) => {

    return axios
        .patch(
            baseUrl + url, data,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('panelToken')}` } }
        )
        .then((response) => {
            return response
        })
        .catch((error) => {
            console.log(error);
            return error

        });
}

const get = (url, data) => {

    return axios
        .get(
            baseUrl + url,
            { headers: { 'Authorization': `Bearer ${localStorage.getItem('panelToken')}` } }

        )
        .then((response) => {
            // console.log(response);
            return response
        })
        .catch((error) => {
            console.log(error);
            return error

        });
}
const serverCall = async (url, data, type, custom) => {
    let responseData;
    const fetchData = [];
    if (type === 'login') {
        const responsee = await login(url, data)
        responseData = responsee
    }
    if (type === 'post') {
        const responsee = await post(url, data)
        responseData = responsee
    }
    if (type === 'get') {
        const response = await get(url, data)
        responseData = response
    }
    if (type === 'patch') {
        const responsee = await patch(url, data)
        responseData = responsee
    }

    return responseData


}

export default serverCall