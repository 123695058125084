import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import '../src/assets/less/main.less'
import './registerServiceWorker'
import VuePapaParse from 'vue-papa-parse'


const app = createApp(App)
app.use(VuePapaParse)
app.component('Datepicker', Datepicker);
app.use(store).use(router).mount('#app');
