<template>
    <div class="dropDown">
        <section class="dropDownMenuWrapper" :class="{ 'dropDownMenuWrapper--dark': isDarkMode, 'dropDownMenuWrapper--noTitle': !menuTitle }">

            <button class="dropDownMenuButton" ref="menu" @click="openClose" :class="{ 'dropDownMenuButton--dark': isDarkMode }">
                {{ menuTitle }}
            </button>

            <div class="iconWrapper" :class="{ 'iconWrapper--noTitle': !menuTitle }">
                <div class="bar1" :class="{ 'bar1--open': isOpen, 'bar1--dark': isDarkMode }" />
                <div class="bar2" :class="{ 'bar2--open': isOpen, 'bar2--dark': isDarkMode }" />
                <div class="bar3" :class="{ 'bar3--open': isOpen, 'bar3--dark': isDarkMode }" />
            </div>

            <section class="dropdownMenu" v-if="isOpen" :class="{ 'dropdownMenu--dark': isDarkMode }">
                <div class="menuArrow" :class="{ 'menuArrow--dark': isDarkMode }" />
                <slot />
            </section>

        </section>


    </div>
</template>

<script>
export default {
    props: ["darkMode", "menuTitle"], // Menu title from the parent
    data() {
        return {
            isOpen: false, // Variable if the menu is open or closed
            isDarkMode: false
        }
    },
    methods: {

        catchOutsideClick(event, dropdown) {

            // When user clicks menu — do nothing
            if (dropdown == event.target)
                return false

            // When user clicks outside of the menu — close the menu
            if (this.isOpen && dropdown != event.target)
                return true

        },
        openClose() {
            var _this = this

            const closeListerner = (e) => {

                if (_this.catchOutsideClick(e, _this.$refs.menu))
                    window.removeEventListener('click', closeListerner), _this.isOpen = false

            }

            window.addEventListener('click', closeListerner)

            this.isOpen = !this.isOpen

        },

    },
    watch: {
        darkMode(val) {

            // Force dark mode
            if (!val)
                this.isDarkMode = false

            // Force dark mode
            if (val == 'force')
                this.isDarkMode = true

            // Switch dark / light mode automatically according to what user prefer
            if (val == 'auto' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
                this.isDarkMode = true

        }
    }


}
</script>

<style>

</style>