<template>
  <div>
    <panel />
  </div>
</template>

<script>
import Panel from '../components/Panel.vue'
export default {
  components: { Panel },

}
</script>

<style>

</style>