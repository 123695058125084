<template>
  <nav-bar v-if="$route.name !== 'Login'" />
  <router-view />

</template>
<script>
import NavBar from './components/NavBar.vue'
export default {
  components: { NavBar },
  setup() {

  },
}
</script>
<style lang="less">
body {
  padding: 0;
  margin: 0;
  background-color: #f2f2fd;

}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
