<template>
    <div class="home">
        <panel />
    </div>
</template>

<script>
import Panel from "./Panel.vue";

export default {
    name: "Home",
    components: {
        Panel,
    },
};
</script>
