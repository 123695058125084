/* eslint-disable no-console */

import { register } from 'register-service-worker'
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(registration) {
      console.log('Service worker has been registered.')
      registration.update()

    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available;')
      registration.update()
      console.log('updated');
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })

  var refreshing;
  if(navigator.serviceWorker){
    navigator.serviceWorker.addEventListener("controllerchange", function () {
      console.log('controllerchange');
      if (refreshing) return
      console.log('before reload');
      window.location.reload();
      console.log('after reload');
      refreshing = true
    })
  }
 
}
