import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { publicPath } from '../../vue.config'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/panel',
    name: 'Panel',
    meta: {
      access: 'all',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Panel.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      access: 'all',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: publicPath,

})


router.beforeEach((to, from) => {
  const isAuth = localStorage.getItem("isAuthenticated");
  const isAdmin = localStorage.getItem('isAdminPanel');
  if ((!isAuth || isAuth === "null" || isAuth !== '867c992a-a24a-4438-a7db-40bd18b42ddd') && to.name !== "Login") {
    router.push({ name: "Login" });
  }
  if ((!isAdmin || isAdmin === 'false' || isAdmin !== '864de6b1-0edd-4fb2-b9f3-39cc382c0278') && to.meta.access === "admin") {
    router.push({ name: "Panel" })
  }
});


export default router
